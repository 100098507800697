<template>
  <div class="basicinformation-root">
    <div class="top">
      <div class="flat">Квартиры</div>
      <div
        v-if="minPrice"
        class="price"
      >
        от {{ $priceFormat({amount: Number(minPrice)}) }}
        <span class="currency">₽</span>
      </div>
      <div class="items-list">
        <div
          v-for="(item) in values"
          :key="`basicinformation-price-${item.message}`"
          class="item"
        >
          <NuxtImg
            loading="lazy"
            placeholder
            width="16px"
            height="16px"
            :src="String(item.icon)"
          />
          <div class="item-value">{{ item.message }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="presentLink"
      class="btn primary --shadow"
    >
      <NuxtLink
        target="_blank"
        :to="presentLink"
      >
        <span>Презентация комплекса</span>
        <img
          src="~/assets/img/svg/basic-information/presentation.svg"
          alt="img"
        >
      </NuxtLink>
    </div>

    <div
      v-if="linkUrl"
      class="btn primary --shadow"
      @click="sendYm"
    >
      <a
        :href="linkUrl"
        target="_blank"
      >{{ linkTitle }}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  BasicInformationAboutProjectBlockAdditionalInformation
} from '~/cms/blocks/BasicInformationAboutProjectBlock.vue';
import { metricsHelper } from '~/utils/forms/metricsHelper';

const { $priceFormat } = useNuxtApp();
const props = defineProps<{
	values: BasicInformationAboutProjectBlockAdditionalInformation[];
	presentLink: string | null;
	minPrice?: string;
	linkUrl?: string;
	linkTitle?: string;
}>();
const $route = useRoute();

function sendYm () {
  const slug = ($route.path).split('/').pop();

  metricsHelper({
    goal: `dload_present_${slug}`
  });
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.basicinformation-root {
	border-radius: 23.479px;
	border: 2.348px solid var(--primary-15);
	background: var(--primary-10);
	box-shadow: 0 6px 8px 0 #dbdbdb;

	& .flat {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%;
		margin-bottom: 6px;
	}

	& .currency {
		font-size: 22px;
	}

	& .top {
		padding: 20px 22px 28px;
	}

	& .price {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 130%;
		color: var(--primary-80);
		margin-bottom: 16px;
	}

	& .items-list {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	& .item {
		display: flex;
		align-items: center;
		gap: 10px;

		& > img {
			min-width: 16px;
			min-height: 16px;
		}
	}

	& .item-value {
		font-size: 17px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
		color: var(--primary-80);
	}

	& .btn {
		width: auto;
		margin: 0 6px 12px;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;

		& > a {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
}
</style>
